<template>
    <div class="absolute bottom-0 right-0 mb-4 mr-4 flex flex-col">
        <div v-if="showCopiedMessage">
            <div class="text-sm text-black mt-2">
                Text copied to clipboard!
            </div>
        </div>
        <div class="flex">
            <div class="mt-1">
                <button @click="showPopup = true" class="bg-white hover:shadow-inner px-3 py-2 text-sm font-medium border border-gray-200 rounded-s-lg focus:z-10 focus:text-[#FFAC48]">
                    <img :src="'/images/download.svg'" alt="download.svg" width="30px" height="30px" >
                </button>
            </div>
            <div class="mt-1">
                <button @click="copyText" class="hover:shadow-inner px-3 py-2 text-sm font-medium bg-white border border-gray-200 focus:z-10 focus:text-[#FFAC48]">
                    <img :src="'/images/copy.svg'" alt="copy.svg" width="30px" height="30px" >
                </button>
            </div>
            <div class="mt-1">
                <button v-if="!isSpeaking" @click="handleClick" class="hover:shadow-inner px-3 py-2 text-sm font-medium bg-white border border-gray-200 rounded-e-lg focus:z-10 focus:text-[#FFAC48]">
                    <img :src="'/images/play.svg'" alt="play.svg" width="30px" height="30px" >
                </button>
                <button v-if="isSpeaking" @click="onStopClick" class="hover:shadow-inner px-3 py-2 text-sm font-medium bg-white border border-gray-200 rounded-e-lg focus:z-10 focus:text-[#FFAC48]">
                    <img :src="'/images/pause.svg'" alt="pause.svg" width="30px" height="30px" >
                </button>
            </div>
        </div>

    </div>
    <div v-if="showPopup" class="popup-overlay">
        <div class="popup-content bg-white md:w-[450px] md:h-[300px] rounded-md border-2 border-gray-400 shadow-2xl flex items-center my-auto relative">
            <div @click="downloadAsTextFile" class="mx-auto align-center border rounded-lg px-4 py-4 flex flex-col justify-center items-center hover:bg-gray-200">
                <img :src="'/images/text_file.svg'" alt="text_file.svg" width="50px" height="50px" >
                <button class="my-4 font-bold text-sm">Download as Text File</button>
            </div>
            <div @click="downloadAsDocFile" class="mx-auto align-center border rounded-lg px-4 py-4 flex flex-col justify-center items-center hover:bg-gray-200">
                <img :src="'/images/doc_files.svg'" alt="doc_file.svg" width="50px" height="50px" >
                <button class="my-4 font-bold text-sm">Download as DOC File</button>
            </div>
            <button @click="closePopup" class="absolute top-1 right-1 inline-flex items-center my-auto z-50 px-2 py-2 rounded bg-[#FFAC48] hover:bg-[#fb8900]">
                <svg xmlns="http://www.w3.org/2000/svg" width="20px" height="20px" viewBox="0 0 24 24" fill="none">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M5.29289 5.29289C5.68342 4.90237 6.31658 4.90237 6.70711 5.29289L12 10.5858L17.2929 5.29289C17.6834 4.90237 18.3166 4.90237 18.7071 5.29289C19.0976 5.68342 19.0976 6.31658 18.7071 6.70711L13.4142 12L18.7071 17.2929C19.0976 17.6834 19.0976 18.3166 18.7071 18.7071C18.3166 19.0976 17.6834 19.0976 17.2929 18.7071L12 13.4142L6.70711 18.7071C6.31658 19.0976 5.68342 19.0976 5.29289 18.7071C4.90237 18.3166 4.90237 17.6834 5.29289 17.2929L10.5858 12L5.29289 6.70711C4.90237 6.31658 4.90237 5.68342 5.29289 5.29289Z" fill="#0F1729"/>
                </svg>
            </button>
        </div>
    </div>

</template>
<script >
export default {
    props: {
        textToCopy: {
            type: String,
            required: true
        },
        editortext: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            isSpeaking: false,
            showPopup: false,
            currentUtterance: null,
            currentBoundary: null,
            showCopiedMessage: false,
        };
    },
    methods: {
        async copyText() {
            try {
                await navigator.clipboard.writeText(this.textToCopy);
                this.showCopiedMessage = true;
                setTimeout(() => {
                    this.showCopiedMessage = false;  // Hide the message after 1 seconds
                }, 4000);
                console.log('Text copied to clipboard');
            } catch (err) {
                console.error('Failed to copy text: ', err);
            }
        },

        handleClick() {
            console.log('textToCopy:', this.textToCopy);
            if (this.isSpeaking) {
                this.stopSpeech(); // Stop any current speech before starting new
            }
            const text = this.textToCopy;
            if (!text) {
                console.error('No text to speak');
                return;
            }
            let start = 0;

            if (this.currentBoundary != null) {
                start = this.currentBoundary;
            }

            const utterance = new SpeechSynthesisUtterance(text.substring(start));

            utterance.onboundary = (event) => {
                this.currentBoundary = event.charIndex + start;
            };

            utterance.onend = () => {
                console.log('Speech has finished.');
                this.isSpeaking = false;
                this.currentBoundary = null;
            };

            this.currentUtterance = utterance;
            window.speechSynthesis.speak(utterance);
            this.isSpeaking = true;
        },


        onStopClick() {
            this.stopSpeech();
        },
        stopSpeech() {
            if (this.currentUtterance) {
                window.speechSynthesis.cancel();
                this.currentUtterance = null;
                this.isSpeaking = false;
            }
        },

        closePopup() {
            this.showPopup = false;
        },

        downloadAsTextFile() {
            const blob = new Blob([this.editortext], { type: 'text/plain' });
            const url = URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = 'content.txt';
            a.click();
            URL.revokeObjectURL(url);
            this.closePopup();
        },

        downloadAsDocFile() {
            const content = `
            <html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:w="urn:schemas-microsoft-com:office:word" xmlns="http://www.w3.org/TR/REC-html40">
            <head><meta charset="utf-8"><title>Document</title></head><body>
            <div>${this.editortext}</div>
            </body></html>
          `;

            const blob = new Blob(['\ufeff', content], {
                type: 'application/msword'
            });

            const url = URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = 'content.doc';
            a.click();
            URL.revokeObjectURL(url);
            this.closePopup();
        },
    }
}
</script>
